<template>
  <div class="container-fluid">
    <third-party-service-form
      :loading="loading"
      :thirdPartyServiceData="thirdPartyService"
      :formErrors="formErrors"
      @thirdPartyServiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultThirdPartyService from "../defaultThirdPartyService";
import ThirdPartyServiceForm from "../partials/ThirdPartyServiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ThirdPartyServiceForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      thirdPartyService: cloneDeep(defaultThirdPartyService),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(thirdPartyService) {
      this.loading = true;

      const thirdPartyServiceData = cloneDeep(thirdPartyService);
      delete thirdPartyServiceData.id;

      try {
        await this.$store.dispatch(
          "thirdPartyServices/add",
          thirdPartyServiceData
        );
        this.$notify({
          type: "success",
          message: this.$t("THIRD_PARTY_SERVICES.THIRD_PARTY_SERVICE_ADDED"),
        });
        const thirdPartyService = await this.$store.getters[
          "thirdPartyServices/thirdPartyService"
        ];
        this.$emit("onViewThirdPartyService", thirdPartyService, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
