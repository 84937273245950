<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ thirdPartyService.name }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.TYPE") }}</dt>
        <dd class="col-sm-8">
          <div>{{ thirdPartyService.service_type }}</div>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("THIRD_PARTY_SERVICES.SERVICE_ACTIVE") }}
        </dt>
        <dd class="col-sm-8">
          <div>
            {{
              thirdPartyService.is_active ? $t("COMMON.YES") : $t("COMMON.NO")
            }}
          </div>
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="thirdPartyService.organization" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
        <dd class="col-sm-8">
          <reseller :reseller="thirdPartyService.reseller" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatDate(thirdPartyService.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatDate(thirdPartyService.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  name: "third-party-service-view-global",

  components: {},

  props: ["thirdPartyService"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    thirdPartyService(thirdPartyService) {},
  },
};
</script>
