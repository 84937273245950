export const STATUS_PENDING = "PENDING";
export const STATUS_SUCCESS = "SUCCESS";
export const STATUS_FAILED = "FAILED";
export const ACTION_CREATE = "CREATE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_DELETE = "DELETE";

export const SERVICE_MODE_TEST = "test";
export const SERVICE_MODE_PROD = "prod";

export const SERVICE_TYPE_QUICKBOOKS = "QUICKBOOKS";

export const SERVICES_MODES = [
  { code: SERVICE_MODE_TEST, label: "Test" },
  { code: SERVICE_MODE_PROD, label: "Prod" },
];

export const SERVICES_TYPES = [
  {
    code: SERVICE_TYPE_QUICKBOOKS,
    label: "QuickBooks",
    fields: [
      {
        key: "realm_id",
        label: "realm_id",
        type: "text",
        descriptions: "Une petite description",
      },
      {
        key: "client_id",
        label: "client_id",
        type: "text",
      },
      {
        key: "client_secret",
        label: "client_secret",
        type: "text",
      },
    ],
  },
];