<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!thirdPartyService">
      <span class="loader"></span>
    </span>

    <div v-if="thirdPartyService" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <third-party-service-view-global
            :thirdPartyService="thirdPartyService"
          />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <third-party-service-view-logs
            :thirdPartyService="thirdPartyService"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ThirdPartyServiceViewLogs from "../partials/ThirdPartyServiceViewLogs.vue";
import ThirdPartyServiceViewGlobal from "../partials/ThirdPartyServiceViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ThirdPartyServiceViewGlobal,
    ThirdPartyServiceViewLogs,
  },

  mixins: [],

  props: {
    thirdPartyServiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      thirdPartyService: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "thirdPartyServices/get",
          this.thirdPartyServiceId
        );
        this.thirdPartyService =
          this.$store.getters["thirdPartyServices/thirdPartyService"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
