<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!thirdPartyService">
      <span class="loader"></span>
    </span>
    <third-party-service-form
      v-if="thirdPartyService && !loading"
      :loading="loading"
      :thirdPartyServiceData="thirdPartyService"
      :formErrors="formErrors"
      @thirdPartyServiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import ThirdPartyServiceForm from "../partials/ThirdPartyServiceForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultThirdPartyService from "../defaultThirdPartyService";

export default {
  layout: "DashboardLayout",

  components: { ThirdPartyServiceForm },

  mixins: [alertLeave],

  props: {
    thirdPartyServiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      thirdPartyService: cloneDeep(defaultThirdPartyService),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "thirdPartyServices/get",
          this.thirdPartyServiceId
        );
        this.thirdPartyService =
          this.$store.getters["thirdPartyServices/thirdPartyService"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(thirdPartyService) {
      this.loading = true;
      const thirdPartyServiceData = cloneDeep(thirdPartyService);

      try {
        await this.$store.dispatch(
          "thirdPartyServices/update",
          thirdPartyServiceData
        );
        this.$notify({
          type: "success",
          message: this.$t("THIRD_PARTY_SERVICES.THIRD_PARTY_SERVICE_UPDATED"),
        });
        const thirdPartyService = await this.$store.getters[
          "thirdPartyServices/thirdPartyService"
        ];
        this.$emit("onViewThirdPartyService", thirdPartyService, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
