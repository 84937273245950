export default {
  type: "third-party-services",
  name: null,
  service_type: null,
  mode: null,
  test_config: null,
  prod_config: null,
  relationshipNames: [
    "reseller",
    "organization",
    "createdBy",
    "updatedBy",
  ],
  reseller: {
    type: "resellers",
    id: null,
  },
  organization: null,
  createdBy: {
    type: "users",
    id: null,
  },
  updatedBy: {
    type: "users",
    id: null,
  },
};
