<template>
  <div class="container-fluids" v-if="serviceType">
    <div>
      <h5 v-if="title">{{ title }}</h5>

      <!-- <el-col :xs="24" :sm="24" :md="16" class="mb-2"  -->
      <div
        class="mb-2 form-wrapper full"
        v-for="field in fields"
        :key="field.key"
      >
        <div v-if="field.type === 'boolean'">
          <base-checkbox
            :disabled="isDisabled"
            v-model="config[field.key]"
            class="mb-2"
          >
            <span class="form-control-label">
              {{ field.label.toUpperCase() }}
            </span>
          </base-checkbox>
        </div>
        <base-input
          v-else
          v-model="config[field.key]"
          :type="field.type"
          :label="field.label.toUpperCase()"
          :disabled="isDisabled"
          :placeholder="field.label"
          :rows="field.type === 'textarea' ? 4 : undefined"
        />

        <!-- description -->
        <small v-if="field?.description ?? null" class="text-xs">
          {{ field.description }}
        </small>

        <!-- <validation-error :errors="apiValidationErrors.config[field.key]" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, Row, Col, Card, Button } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import { SERVICES_TYPES } from "@/constants/thirdPartyServices";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "ThirdPartyConfigEditor",
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Card.name]: Card,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    BaseInput,
    BaseCheckbox,
    ValidationError,
  },
  /* props: {
    value: {
        type: [Object, Null], // Accepte Object ou null
        required: true, // Cela signifie qu'une valeur doit être fournie, même si c'est null
        default: null, // Pour gérer une valeur par défaut
    },
    serviceType: {
      type: [String, Null], // Accepte à la fois String et null
      required: true,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  }, */
  props: ["value", "serviceType", "title", "disabled"],

  mixins: [alertLeave, formMixin],
  data() {
    console.log("value", this.value);
    console.log("value", this.serviceType);
    return {
      config: this.value || {},
    };
  },
  computed: {
    selectedType() {
      console.log("selectedType", this.serviceType);
      return SERVICES_TYPES.find(({ code }) => code === this.serviceType);
    },
    fields() {
      return this.selectedType?.fields ?? [];
    },
    isDisabled() {
      return this.disabled ?? false;
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.config = newVal ?? {};
      },
      deep: true,
      immediate: true,
    },
    config: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>
