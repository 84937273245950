<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <base-input
        :label="$t('COMMON.RESELLER')"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :reseller="thirdPartyService.reseller.id"
          :filterable="true"
          :showAll="false"
          :allowNone="false"
          :disabled="!!thirdPartyService.id"
          @resellerChanged="
            (resellerId) => {
              thirdPartyService.reseller.id = resellerId;
              thirdPartyService.organization = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div>

    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="$t('COMMON.ORGANIZATION')"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="thirdPartyService.organization?.id ?? null"
          :filterable="true"
          :showAll="false"
          :allowNone="true"
          :filterReseller="thirdPartyService.reseller.id"
          :disabled="!!thirdPartyService.id"
          @organizationChanged="
            (organizationId) => {
              thirdPartyService.organization = {
                id: organizationId,
                type: 'organizations',
              };
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="form-wrapper mb-2"
      :class="{ full: !thirdPartyService.service_type }"
    >
      <base-input
        :label="`${$t('COMMON.TYPE')} (*)`"
        :placeholder="$t('COMMON.TYPE')"
      >
        <el-select
          v-model="thirdPartyService.service_type"
          :placeholder="$t('COMMON.TYPE')"
          :filterable="false"
          :disabled="loading"
          :multiple="false"
        >
          <el-option
            v-for="type in SERVICES_TYPES"
            :key="type.code"
            :value="type.code"
            :label="type.label"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.service_type" />
    </div>

    <div class="form-wrapper mt-3" v-if="thirdPartyService.service_type">
      <base-input
        :label="`${$t('THIRD_PARTY_SERVICES.SERVICE_MODE')} (*)`"
        :placeholder="$t('THIRD_PARTY_SERVICES.SERVICE_MODE')"
      >
        <el-radio-group v-model="thirdPartyService.mode">
          <el-radio
            v-for="mode in SERVICES_MODES"
            :key="mode.code"
            :label="mode.code"
          >
            {{ mode.label }}
          </el-radio>
        </el-radio-group>
      </base-input>

      <validation-error :errors="apiValidationErrors.mode" />
    </div>

    <div class="form-wrapper full" v-if="thirdPartyService.service_type">
      <el-tabs v-model="activeTabMode" :stretch="true">
        <el-tab-pane
          :label="$t('THIRD_PARTY_SERVICES.SERVICE_TEST_CONFIG')"
          :name="SERVICE_MODE_TEST"
        >
          <ThirdPartyServiceConfigEditor
            v-model="thirdPartyService.test_config"
            :serviceType="thirdPartyService.service_type"
            :disabled="loading"
            :title="$t('THIRD_PARTY_SERVICES.SERVICE_TEST_CONFIG')"
          />
        </el-tab-pane>

        <el-tab-pane
          :label="$t('THIRD_PARTY_SERVICES.SERVICE_PROD_CONFIG')"
          :name="SERVICE_MODE_PROD"
        >
          <ThirdPartyServiceConfigEditor
            v-model="thirdPartyService.prod_config"
            :serviceType="thirdPartyService.service_type"
            :disabled="loading"
            :title="$t('THIRD_PARTY_SERVICES.SERVICE_PROD_CONFIG')"
          />
        </el-tab-pane>
      </el-tabs>

      <validation-error :errors="apiValidationErrors.test_config" />
      <validation-error :errors="apiValidationErrors.prod_config" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          thirdPartyService.id
            ? $t("THIRD_PARTY_SERVICES.EDIT_THIRD_PARTY_SERVICE")
            : $t("THIRD_PARTY_SERVICES.ADD_THIRD_PARTY_SERVICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Select,
  Option,
  Row,
  Col,
  Card,
  Button,
  Tabs,
  TabPane,
  Form,
  Radio,
  RadioGroup,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import {
  SERVICES_TYPES,
  SERVICES_MODES,
  SERVICE_MODE_TEST,
  SERVICE_MODE_PROD,
  SERVICE_TYPE_QUICKBOOKS,
} from "@/constants/thirdPartyServices";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ThirdPartyServiceConfigEditor from "./ThirdPartyServiceConfigEditor.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ResellerSelector,
    BaseCheckbox,
    ThirdPartyServiceConfigEditor,
    [Row.name]: Row,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },

  mixins: [formMixin],

  props: ["thirdPartyServiceData", "formErrors", "loading"],

  data() {
    let thirdPartyServiceData = { ...this.thirdPartyServiceData };

    const me = this.$store.getters["profile/me"];

    thirdPartyServiceData.createdBy.id = me.id;
    thirdPartyServiceData.updatedBy.id = me.id;

    return {
      thirdPartyService: thirdPartyServiceData,
      activeTabMode: SERVICE_MODE_TEST,
      SERVICES_TYPES,
      SERVICES_MODES,
      SERVICE_MODE_TEST,
      SERVICE_MODE_PROD,
      SERVICE_TYPE_QUICKBOOKS,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let thirdPartyServiceData = cloneDeep(this.thirdPartyService);

      thirdPartyServiceData.name = SERVICES_TYPES.find(
        (e) => e.code == thirdPartyServiceData.service_type
      ).label;

      this.$emit("thirdPartyServiceSubmitted", thirdPartyServiceData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    thirdPartyServiceData(thirdPartyServiceData) {
      if (thirdPartyServiceData) {
        this.thirdPartyService = {
          ...this.thirdPartyService,
          ...cloneDeep(thirdPartyServiceData),
        };
      }
    },
  },
};
</script>
