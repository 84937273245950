<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ `${thirdPartyService.name}` }} - {{ $t("COMMON.LOGS") }}</h3>
    </div>
    <log-list-table :filterSubject="thirdPartyService" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "third-party-service-view-logs",

  components: { LogListTable },

  props: ["thirdPartyService"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
